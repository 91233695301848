export default {
    navigation: {
        dev: 'Карты',
        referrals: 'Друзья',
        home: 'База',
        bonus: 'Бонус',
        airDrop: 'AirDrop'
    },

    cards: {
        emptyList: 'В этом разделе пока нет карт для покупки...',
        profitHour: 'Прибыль в час',

        chargeOn: 'С зарядом',
        charge: 'Зарядить',
        payCard: 'Купить',
        payCardOk: 'Успешно!'

    },

    home: {
        tapProfit: 'Тап-профит',
        levelUp: 'След. lvl',
        profitOnHour: 'Прибыль в час',
        level: 'Уровень',
        dragonPower: 'Мощность',
        boosts: 'Бусты'
    },

    referrals: {
        inviteBlockTitle: 'Приглашайте друзей',
        inviteBlockText: 'И получайте монеты для вашего уровня',
        totalReward: 'Получено:',
        myFriends: 'Мои друзья',
        inviteButton: 'Пригласить друзей',

        modalTitle: 'Награда для вашего уровня',
        modalInfo: 'за каждого друга',

        inveteMessageText: 'Присоединяйся и прокачай своего дракона!',
        notifiCopiedLink: 'Ссылка скопирована',

        emptyList: 'У вас пока нет приглашенных друзей'
    },

    bonus: {
        title: 'Задания',
        description: 'Выполняйте задания чтобы получить больше монет',
        everyday: 'Бонусы',
        everyday_reward: 'Ежедневная награда',

        modalTitle: 'Заходите каждый день чтобы получить монеты',
        modalDay: 'День',

        claimReward: 'Получить награду',
        notifiClaimOk: 'Награда получена'
    },

    airDrop: {
        title: 'Выполните все задания чтобы получить AirDrop',
        taskTap: 'Задания',
        withdrawalTap: 'Withdrawal',
        soon: 'Скоро',

        startTask: 'Выполнить',
        repeatTask: 'Повторить',
        checkStatusTask: 'Проверить',
        rewardOk: 'Награда получена',
        rewardOkNotifi: 'Награда получена',
        taskDone: 'Задание выполнено',
        taskNoDone: 'Задание не выполнено',
        openTarget: 'Открыть',

        infoCodeText: 'Введите полученный код из задания чтобы получить награду',
        inputApproveCode: 'Введите код',
        sendCode: 'Получить награду',
        errorCode: 'Неверный код'
    },

    boosters: {
        title: 'Улучшения',
        tapProfit: 'Прибыль за тап',
        dragonPower: 'Сила дракона',

        currentProfitPerTap: 'Прибыль за тап',
        profitPerTapDescription: 'Увеличивает количество монет которое вы получаете за один тап на 1',

        currentDragonPower: 'Мощность дракона',
        dragonPowerDescription: 'Увеличивает мощность вашего дракона на 500',

        upgradeButton: 'Улучшить',
        upgradeOk: 'Улучшено!'
    },

    other: {
        noCoins: 'Недостаточно монет'
    },

    modalGameInfo: {
        title: 'Информация',
        description: "Lorem ipsum odor amet, consectetuer adipiscing elit. Ridiculus odio quisque aptent praesent himenaeos, mus vitae. Lorem ipsum odor amet, consectetuer adipiscing elit. Ridiculus odio quisque aptent praesent himenaeos, mus vitae.",
        button: "В игру"
    }
};
