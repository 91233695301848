export default function numberFormat(number, asCurrency = false) {
    // Округление числа вниз
    const roundedNumber = Math.floor(number);

    if (asCurrency) {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            currencyDisplay: 'symbol',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: true, // Используем разделитель
        });
        return currencyFormatter.format(roundedNumber);
    } else {
        const numberFormatter = new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2,
            useGrouping: true, // Используем разделитель
        });
        return numberFormatter.format(roundedNumber);
    }
}
