import { createI18n } from 'vue-i18n';
import en from '@/locales/lang/en.js';
import ru from '@/locales/lang/ru.js';

const messages = {
    en: en,
    ru: ru,
};

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'ru',
    messages,
});

export default i18n;