import { createApp } from 'vue';
import App from './App.vue';

import router from './router';

import '@/assets/css/style.css';

import i18n from './locales/i18n';

const app = createApp(App);

app.use(i18n);

app.use(router);

import pinia from './store';
import { useUserStore } from '@/store/user.js';
app.use(pinia);

const userStore = useUserStore();
app.config.globalProperties.$userStore = userStore;

import postman from '@/fnc/postman.js';
app.config.globalProperties.$postman = postman;

import abbreviateNumber from '@/fnc/abbreviateNumber.js';
app.config.globalProperties.$abbreviateNumber = abbreviateNumber;

import truncateString from '@/fnc/truncateString.js';
app.config.globalProperties.$truncateString = truncateString;

import numberFormat from '@/fnc/numberFormat.js';
app.config.globalProperties.$numberFormat = numberFormat;

const urlCardImages = `${process.env.VUE_APP_API_DOMAIN}_images/cards/`;
app.config.globalProperties.$urlCardImages = urlCardImages;

const urlAvatarImages = `${process.env.VUE_APP_API_DOMAIN}_images/avatars/`;
app.config.globalProperties.$urlAvatarImages = urlAvatarImages;

const urlTaskImages = `${process.env.VUE_APP_API_DOMAIN}_images/tasks/`;
app.config.globalProperties.$urlTaskImages = urlTaskImages;

app.config.globalProperties.TWA = window.Telegram.WebApp;

import findCardId from '@/fnc/findCardId.js';
app.config.globalProperties.$findCardId = findCardId;

import { TonConnectUI } from '@townsquarelabs/ui-vue';
const TON = new TonConnectUI({
    manifestUrl: "https://dragon-app.ikey333.ru/ton-manifest.json",
    twaReturnUrl: 'https://t.me/DragonBotDevelopmentBot'
});
app.config.globalProperties.$TON = TON;

app.mount('#app');