export default function rewardOnReferral(level) {
    if (level == 1) return '10K';
    if (level == 2) return '20K';
    if (level == 3) return '30K';
    if (level == 4) return '50K';
    if (level == 5) return '100K';
    if (level == 6) return '200K';
    if (level == 7) return '500K';
    if (level == 8) return '1M';

    return '∞';
}