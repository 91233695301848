<template>
    <footer class="footer">
        <div class="container">
            <div class="footer__inner">
                <ul class="footer__nav">
                    <router-link v-for="(t, i) in tabs" :key="i" :to="generateRoute(t)" custom
                        v-slot="{ href, navigate }">
                        <li :class="{ 'footer__nav-main': isRouteActive(t.route) }">
                            <a :href="href" @click="navigate"
                                :class="{ 'footer__nav-active': isRouteActive(t.route), 'footer__nav-link': !isRouteActive(t.route) }">
                                <DevIcon v-if="t.icon == 'DevIcon'" />
                                <ReferalsIcon v-if="t.icon == 'ReferalsIcon'" />
                                <HomeIcon v-if="t.icon == 'HomeIcon'" />
                                <BonusIcon v-if="t.icon == 'BonusIcon'" />
                                <AirDropIcon v-if="t.icon == 'AirDropIcon'" />

                                <span v-if="t.route == 'cards'">{{ $t('navigation.dev') }}</span>
                                <span v-if="t.route == 'referrals'">{{ $t('navigation.referrals') }}</span>
                                <span v-if="t.route == 'home'">{{ $t('navigation.home') }}</span>
                                <span v-if="t.route == 'bonus'">{{ $t('navigation.bonus') }}</span>
                                <span v-if="t.route == 'airdrop'">{{ $t('navigation.airDrop') }}</span>
                            </a>
                        </li>
                    </router-link>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
import DevIcon from '@/components/icons/DevIcon.vue';
import ReferalsIcon from '@/components/icons/ReferalsIcon.vue';
import HomeIcon from '@/components/icons/HomeIcon.vue';
import BonusIcon from '@/components/icons/BonusIcon.vue';
import AirDropIcon from '@/components/icons/AirDropIcon.vue';

export default {
    components: {
        DevIcon, ReferalsIcon, HomeIcon, BonusIcon, AirDropIcon
    },

    data() {
        return {
            tabs: [
                {
                    route: 'cards',
                    name: 'DEV',
                    icon: 'DevIcon',
                    active: false
                },

                {
                    route: 'referrals',
                    name: 'Referals',
                    icon: 'ReferalsIcon',
                    active: false
                },

                {
                    route: 'home',
                    name: 'Home',
                    icon: 'HomeIcon',
                    active: true
                },

                {
                    route: 'bonus',
                    name: 'Bonus',
                    icon: 'BonusIcon',
                    active: false
                },

                {
                    route: 'airdrop',
                    name: 'AirDrop',
                    icon: 'AirDropIcon',
                    active: false
                },
            ]
        };
    },

    methods: {
        generateRoute(tab) {
            if (tab.route === 'cards') {
                return { name: tab.route, params: { shop: 1 } };
            } else {
                return { name: tab.route };
            }
        },

        isRouteActive(route) {
            if (route == 'home' && (this.$route.path == '/' || this.$route.path == '')) {
                return true;
            }

            return this.$route.path.startsWith(`/${route}`);
        }
    }
}
</script>